// App.js - WEB
import React, { Component, lazy, Suspense } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import Portal from '@material-ui/core/Portal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar/dist/Calendar.css';
import "react-datepicker/dist/react-datepicker.css";

const InfoPage = lazy(() => import('../../blocks/info-page/src/InfoPageBlock'));
const AlertBlock = lazy(() => import('../../blocks/alert/src/AlertBlock.web'));
const Quotemanagement2 = lazy(() => import("../../blocks/quotemanagement2/src/Quotemanagement2"));
const AccountGroups = lazy(() => import("../../blocks/accountgroups/src/AccountGroups"));
const GoalsAndProgress = lazy(() => import("../../blocks/accountgroups/src/GoalsAndProgress.web"));
const GoalDetail = lazy(() => import("../../blocks/accountgroups/src/GoalDetail.web"));
const CoachGoalDetail = lazy(() => import("../../blocks/accountgroups/src/CoachGoalDetail.web"));
const BulkUploading = lazy(() => import("../../blocks/bulkuploading/src/BulkUploading"));
const SocialMediaAccountLogin = lazy(() => import("../../blocks/social-media-account/src/SocialMediaAccountLogin"));
const SocialMediaAccountRegistration = lazy(() => import("../../blocks/social-media-account/src/SocialMediaAccountRegistration"));
const AssessmentTest = lazy(() => import("../../blocks/assessmenttest/src/AssessmentTest"));
const CoachAssessmentBank = lazy(() => import("../../blocks/assessmenttest/src/CoachAssessmentBank.web"));
const WeeklySchedule = lazy(() => import("../../blocks/assessmenttest/src/WeeklySchedule.web"));
const DownloadOptions = lazy(() => import("../../blocks/downloadoptions/src/DownloadOptions"));
const DocumentOpener = lazy(() => import("../../blocks/documentopener/src/DocumentOpener"));
const SocialMediaAccountLoginScreen = lazy(() => import("../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen"));
// const ForgotPassword = lazy(() => import("../../blocks/forgot-password/src/ForgotPassword"));
const ForgotPasswordOTP = lazy(() => import("../../blocks/forgot-password/src/ForgotPasswordOTP"));
const NewPassword = lazy(() => import("../../blocks/forgot-password/src/NewPassword"));
const RecommendationEngine = lazy(() => import("../../blocks/recommendationengine/src/RecommendationEngine"));
const TermsConditions = lazy(() => import("../../blocks/termsconditions/src/TermsConditions"));
const TermsConditionsDetail = lazy(() => import("../../blocks/termsconditions/src/TermsConditionsDetail"));
const TermsConditionsUsers = lazy(() => import("../../blocks/termsconditions/src/TermsConditionsUsers"));
const Videos = lazy(() => import("../../blocks/videos/src/Videos"));
const InvoiceBilling = lazy(() => import("../../blocks/invoicebilling/src/InvoiceBilling"));
const Catalogue = lazy(() => import("../../blocks/catalogue/src/Catalogue.web"));
const SocialMediaAccountRegistrationScreen = lazy(() => import("../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen"));
const Emailnotifications2 = lazy(() => import("../../blocks/emailnotifications2/src/Emailnotifications2"));
const Cfgoogleanalyticsintegration11 = lazy(() => import("../../blocks/cfgoogleanalyticsintegration11/src/Cfgoogleanalyticsintegration11"));
const CountryCodeSelector = lazy(() => import("../../blocks/country-code-selector/src/CountryCodeSelector"));
const Cfemailcommunicationbwsecassessmentprovider = lazy(() => import("../../blocks/cfemailcommunicationbwsecassessmentprovider/src/Cfemailcommunicationbwsecassessmentprovider"));
const PhoneNumberInput = lazy(() => import("../../blocks/mobile-account-registration/src/PhoneNumberInput"));
const AdditionalDetailForm = lazy(() => import("../../blocks/mobile-account-registration/src/AdditionalDetailForm"));
const Settings2 = lazy(() => import("../../blocks/settings2/src/Settings2"));
const Contactus = lazy(() => import("../../blocks/contactus/src/Contactus"));
const AddContactus = lazy(() => import("../../blocks/contactus/src/AddContactus"));
const Cfteamsintegartion = lazy(() => import("../../blocks/cfteamsintegartion/src/Cfteamsintegartion"));
const Cfhyperpaypaymentgateway = lazy(() => import("../../blocks/cfhyperpaypaymentgateway/src/Cfhyperpaypaymentgateway"));
const MobileAccountLoginBlock = lazy(() => import("../../blocks/mobile-account-login/src/MobileAccountLoginBlock"));
const EmailAccountRegistration = lazy(() => import("../../blocks/email-account-registration/src/EmailAccountRegistration"));
const Categoriessubcategories = lazy(() => import("../../blocks/categoriessubcategories/src/Categoriessubcategories"));
const Adminconsole2 = lazy(() => import("../../blocks/adminconsole2/src/Adminconsole2"));
const Analytics = lazy(() => import("../../blocks/analytics/src/Analytics"));
const Cfcalendlyintegration18 = lazy(() => import("../../blocks/cfcalendlyintegration18/src/Cfcalendlyintegration18"));
const LanguageSupport = lazy(() => import("../../blocks/languagesupport/src/LanguageSupport"));
const EmailAccountLoginBlock = lazy(() => import("../../blocks/email-account-login/src/EmailAccountLoginBlock.web")); 
// const LandingPage = lazy(() => import("../../blocks/landingpage/src/LandingPage"));
const LandingPage = lazy(() => import("../../blocks/landingpage/src/LandingPage.web"));
const CoachLandingPage = lazy(() => import("../../blocks/landingpage/src/CoachLandingPage.web"));
const LanguageOptions = lazy(() => import("../../blocks/languageoptions/src/LanguageOptions"));
const Cfwallet44 = lazy(() => import("../../blocks/cfwallet44/src/Cfwallet44"));
const AdvancedSearch = lazy(() => import("../../blocks/advancedsearch/src/AdvancedSearch"));
const Tasks = lazy(() => import("../../blocks/tasks/src/Tasks"));
const TaskList = lazy(() => import("../../blocks/tasks/src/TaskList.web"));
const TaskListDetails = lazy(() => import("../../blocks/tasks/src/TaskListDetails.web"));
const Task = lazy(() => import("../../blocks/tasks/src/Task"));
const Reviews = lazy(() => import("../../blocks/reviews/src/Reviews"));
const AddReview = lazy(() => import("../../blocks/reviews/src/AddReview"));
const RequestManagement = lazy(() => import("../../blocks/requestmanagement/src/RequestManagement"));
const Rolesandpermissions2 = lazy(() => import("../../blocks/rolesandpermissions2/src/Rolesandpermissions2"));
const Captcha = lazy(() => import("../../blocks/captcha/src/Captcha"));
const Chat = lazy(() => import("../../blocks/chat/src/Chat"));
const ViewChat = lazy(() => import("../../blocks/chat/src/ViewChat"));
const Scheduling = lazy(() => import("../../blocks/scheduling/src/Scheduling"));
const Servicespecificsettingsadmin2 = lazy(() => import("../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2"));
const OTPInputAuth = lazy(() => import("../../blocks/otp-input-confirmation/src/OTPInputAuth"));
const NewPasswordBlock = lazy(() => import("../../blocks/forgot-password/src/NewPassword.web"));
const ForgotPasswordBlock = lazy(() => import("../../blocks/forgot-password/src/PasswordForgot.web"));
const GeneralInformations = lazy(() => import("../../blocks/email-account-registration/src/GeneralInforamtionQone.web"));
const GeneralInformationQTwo = lazy(() => import("../../blocks/email-account-registration/src/GeneralInforamtionQtwo.web"));
const GeneralInformationQThree = lazy(() => import("../../blocks/email-account-registration/src/GeneralInforamtionQthree.web"));
const GeneralInformationQFour = lazy(() => import("../../blocks/email-account-registration/src/GeneralInforamtionQfour.web"));
const ReadyToInspire = lazy(() => import("../../blocks/email-account-registration/src/CreateAccount.web"));
const CoachSignup = lazy(() => import("../../blocks/email-account-registration/src/CoachSignup.web"));
const CaptchaCreateAccount = lazy(() => import("../../blocks/email-account-registration/src/CaptchaCreateAccount.web"));
const CoachAdministration = lazy(() => import("../../blocks/assessmenttest/src/CoachAdministration.web"));
const Dashboard = lazy(() => import("../../blocks/dashboard/src/Dashboard.web"));
const ExecutiveAccountCreation = lazy(() => import("../../blocks/assessmenttest/src/AssessmentQuestions/ExecutiveAccountCreation.web"));
const ExecutiveGeneralInfo = lazy(() => import("../../blocks/assessmenttest/src/AssessmentQuestions/ExecutiveGeneralInfo.web"));
const ExecutiveContactUs = lazy(() => import("../../blocks/assessmenttest/src/AssessmentQuestions/ExecutiveContactUs.web"));
const ExecutiveAssessment = lazy(() => import("../../blocks/assessmenttest/src/AssessmentQuestions/AssessmentQuestions.web"));
const HelpCenter = lazy(() => import("../../blocks/adminconsole2/src/HelpCenter.web"));
const UpcommingSession = lazy(() => import("../../blocks/dashboard/src/UpcommingSession.web"));
const SurveyResult = lazy(() => import("../../blocks/dashboard/src/SurveyResult.web"));
const ExecutiveSignUp = lazy(() => import("../../blocks/email-account-registration/src/ExecutiveSignUp.web"));
const AssessmentAfterAcceptance = lazy(() => import("../../blocks/assessmenttest/src/AssessmentAfterAcceptance/AssessmentAfterAcceptance.web"));
const DashboardHome = lazy(() => import("../../blocks/dashboard/src/DashboardHome.web"));
const CoachSelection = lazy(() => import("../../blocks/email-account-registration/src/CoachSelection.web"));
const CoachSelectionProfile = lazy(() => import("../../blocks/email-account-registration/src/CoachSelectionProfile.web"));
const CoachBookSession = lazy(() => import("../../blocks/scheduling/src/CoachBookSession.web"));
const CoachingResources = lazy(() => import("../../blocks/dashboard/src/DashBoardResources/CoachingResource.web"));
const ClientDashboard = lazy(() => import("../../blocks/dashboard/src/ClientDashboard.web"));
const ClientProfile = lazy(() => import("../../blocks/accountgroups/src/AccountGroups.web"));
const RatingAndReview = lazy(() => import("../../blocks/reviews/src/RatingAndReview.web"));
const ClientRatingAndReview = lazy(() => import("../../blocks/reviews/src/ClientRatingAndReview.web"));
const CoachSettings = lazy(() => import("../../blocks/settings2/src/CoachSettings.web"));
const CoachViewProfile = lazy(() => import("../../blocks/dashboard/src/CoachViewProfile.web"));
const ClientSetting = lazy(() => import("../../blocks/settings2/src/Settings2.web"));
const Sessions = lazy(() => import("../../blocks/scheduling/src/Sessions.web"));
const CoachDetails = lazy(() => import("../../blocks/scheduling/src/CoachDetails.web"));
const SessionDetails = lazy(() => import("../../blocks/scheduling/src/SessionDetails.web"));
const Profile = lazy(() => import("../../blocks/accountgroups/src/ClientViewProfile.web"));
const ComingSoon = lazy(() => import("../../blocks/navigationmenu/src/ComingSoon.web"));
const CoachClientListView = lazy(() => import("../../blocks/accountgroups/src/CoachClientListView.web"));
const CoachMyClientProfile = lazy(() => import("../../blocks/accountgroups/src/CoachMyClientProfile.web"));
const AvailabilityCalendar = lazy(() => import("../../blocks/scheduling/src/AvailabilityCalendar.web"));
const FAQScreen = lazy(() => import("../../blocks/helpcentre/src/FAQScreen.web"));
const PrivacyPolicy = lazy(() => import("../../blocks/termsconditions/src/PrivacyPolicyScreen.web"));
const AboutUs = lazy(() => import("../../blocks/termsconditions/src/AboutUs.web"));
const TakeNewSurvey = lazy(() => import('../../blocks/assessmenttest/src/TakeNewSurvey.web.tsx'));
const BookSessionCoach = lazy(() => import('../../blocks/dashboard/src/BookSessionCoach.web.tsx'));
const BookSessionClient = lazy(() => import('../../blocks/scheduling/src/BookSessionClient.web.tsx'));
const CustomisableUserProfiles = lazy(() => import('../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web'));
const PackageDetails = lazy(() => import('../../blocks/customisableuserprofiles/src/PackageDetails.web'));
import { SurveyAssessmentStyle } from "../../blocks/assessmenttest/src/SurveyAssessment.web";
import Progressbar from "../../components/src/MainProgressBar.web";

const routeMap = {
  BookSessionCoach: {
    component: BookSessionCoach,
    path: "/BookSessionCoach",
    exact: true,
    wrapRoute: "publicRoute"
    // wrapRoute: "protectedRouteForCoach",
  },
  BookSessionClient:{
    component: BookSessionClient,
    path: "/BookSessionClient",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Quotemanagement2: {
    component: Quotemanagement2,
    path: "/Quotemanagement2",
    exact: true,
    wrapRoute: "publicRoute"
  },
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups",
    exact: true,
    wrapRoute: "publicRoute"
  },
  GoalsAndProgress: {
    component: GoalsAndProgress,
    path: "/GoalsAndProgress",
    exact: true,
    wrapRoute: "protectedRouteForExecutive"
  },
  GoalDetail: {
    component: GoalDetail,
    path: "/GoalsAndProgress/GoalDetail/:navigationBarTitleText",
    exact: true,
    wrapRoute: "protectedRouteForExecutive"
  },
  CoachGoalDetail: {
    component: CoachGoalDetail,
    path: "/CoachGoalDetail/:navigationBarTitleText",
    exact: true,
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading",
    exact: true,
    wrapRoute: "publicRoute"
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin",
    exact: true,
    wrapRoute: "publicRoute"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration",
    exact: true,
    wrapRoute: "publicRoute"
  },
  AssessmentTest: {
    component: AssessmentTest,
    path: "/AssessmentTest",
    exact: true,
    wrapRoute: "protectedCoachForAssessment"
  },
  CoachAssessmentBank: {
    component: CoachAssessmentBank,
    path: "/CoachAssessmentBank",
    exact: true,
    wrapRoute: "protectedCoachForPostAssessment"
  },
  WeeklySchedule: {
    component: WeeklySchedule,
    path: "/WeeklySchedule",
    exact: true,
    wrapRoute: "protectedCoachForPostAssessment"
  },
  DownloadOptions: {
    component: DownloadOptions,
    path: "/DownloadOptions",
    exact: true,
    wrapRoute: "publicRoute"
  },
  DocumentOpener: {
    component: DocumentOpener,
    path: "/DocumentOpener",
    exact: true,
    wrapRoute: "publicRoute"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen",
    exact: true,
    wrapRoute: "publicRoute"
  },
  // ForgotPassword: {
  //   component: ForgotPassword,
  //   path: "/ForgotPassword"
  // },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP",
    exact: true,
    wrapRoute: "publicRoute"
  },
  ComingSoon: {
    component: ComingSoon,
    path: "/ComingSoon"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword",
    exact: true,
    wrapRoute: "publicRoute"
  },
  RecommendationEngine: {
    component: RecommendationEngine,
    path: "/RecommendationEngine",
    exact: true,
    wrapRoute: "publicRoute"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsandConditions",
    exact: true,
    wrapRoute: "publicRoute"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail",
    exact: true,
    wrapRoute: "publicRoute"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Videos: {
    component: Videos,
    path: "/Videos",
    exact: true,
    wrapRoute: "publicRoute"
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: "/InvoiceBilling",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue",
    exact: true,
    wrapRoute: "publicRoute"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Cfgoogleanalyticsintegration11: {
    component: Cfgoogleanalyticsintegration11,
    path: "/Cfgoogleanalyticsintegration11",
    exact: true,
    wrapRoute: "publicRoute"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Cfemailcommunicationbwsecassessmentprovider: {
    component: Cfemailcommunicationbwsecassessmentprovider,
    path: "/Cfemailcommunicationbwsecassessmentprovider",
    exact: true,
    wrapRoute: "publicRoute"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput",
    exact: true,
    wrapRoute: "publicRoute"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus",
    exact: true,
    wrapRoute: "publicRoute"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Cfteamsintegartion: {
    component: Cfteamsintegartion,
    path: "/Cfteamsintegartion",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Cfhyperpaypaymentgateway: {
    component: Cfhyperpaypaymentgateway,
    path: "/Cfhyperpaypaymentgateway",
    exact: true,
    wrapRoute: "publicRoute"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock",
    exact: true,
    wrapRoute: "authRoute"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration",
    exact: true,
    wrapRoute: "authRoute"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Cfcalendlyintegration18: {
    component: Cfcalendlyintegration18,
    path: "/Cfcalendlyintegration18",
    exact: true,
    wrapRoute: "publicRoute"
  },
  LanguageSupport: {
    component: LanguageSupport,
    path: "/LanguageSupport",
    exact: true,
    wrapRoute: "publicRoute"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock",
    exact: true,
    wrapRoute: "authRoute"
  },
  CoachLandingPage: {
    component: CoachLandingPage,
    path: "/CoachLandingPage",
    exact: true,
    wrapRoute: "publicRoute"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage",
    exact: true,
    wrapRoute: "publicRoute"
  },
  FAQScreen: {
    component: FAQScreen,
    path: "/FrequentlyAskedQuestions",
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: "/PrivacyPolicy",
  },
  AboutUs: {
    component: AboutUs,
    path: "/AboutUs"
  },
  LanguageOptions: {
    component: LanguageOptions,
    path: "/LanguageOptions",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Cfwallet44: {
    component: Cfwallet44,
    path: "/Cfwallet44",
    exact: true,
    wrapRoute: "publicRoute"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Tasks: {
    component: Tasks,
    path: "/Tasks",
    exact: true,
    wrapRoute: "publicRoute"
  },
  TaskList: {
    component: TaskList,
    path: "/TaskList",
    exact: true,
    wrapRoute: "protectedRouteForExecutive"
  },
  TaskListDetails: {
    component: TaskListDetails,
    path: "/TaskList/TaskListDetails/:navigationBarTitleText",
    exact: true,
    wrapRoute: "protectedRouteForExecutive",
  },
  Task: {
    component: Task,
    path: "/Task",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Reviews: {
    component: Reviews,
    path: "/Reviews",
    exact: true,
    wrapRoute: "publicRoute"
  },
  AddReview: {
    component: AddReview,
    path: "/AddReview",
    exact: true,
    wrapRoute: "publicRoute"
  },
  RequestManagement: {
    component: RequestManagement,
    path: "/RequestManagement",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Captcha: {
    component: Captcha,
    path: "/Captcha",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Chat: {
    component: ComingSoon,
    path: "/Chat",
    exact: true,
    wrapRoute: "protectedRouteForCoach"
  },
  ViewChat: {
    component: ViewChat,
    path: "/ViewChat",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling",
    exact: true,
    wrapRoute: "publicRoute"
  },
  Servicespecificsettingsadmin2: {
    component: Servicespecificsettingsadmin2,
    path: "/Servicespecificsettingsadmin2",
    exact: true,
    wrapRoute: "publicRoute"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth",
    exact: true,
    wrapRoute: "publicRoute"
  },
  GeneralInformations: {
    component: GeneralInformations,
    path: "/GeneralInformations",
    exact: true,
    wrapRoute: "publicRoute"
  },
  GeneralInformationQTwo: {
    component: GeneralInformationQTwo,
    path: "/GeneralInformationQTwo",
    exact: true,
    wrapRoute: "publicRoute"
  },
  GeneralInformationQThree: {
    component: GeneralInformationQThree,
    path: "/GeneralInformationQThree",
    exact: true,
    wrapRoute: "publicRoute"
  },
  GeneralInformationQFour: {
    component: GeneralInformationQFour,
    path: "/GeneralInformationQFour",
    exact: true,
    wrapRoute: "publicRoute"
  },
  ReadyToInspire: {
    component: ReadyToInspire,
    path: "/ReadyToInspire/:activeStep",
    exact: true,
    wrapRoute: "publicRoute"
  },
  ExecutiveAccountCreation: {
    component: ExecutiveAccountCreation,
    path: "/ExecutiveAccountCreation",
    exact: true,
    wrapRoute: "publicRoute"
  },
  ExecutiveGeneralInfo: {
    component: ExecutiveGeneralInfo,
    path: "/ExecutiveGeneralInfo",
    exact: true,
    wrapRoute: "publicRoute"
  },
  ExecutiveContactUs: {
    component: ExecutiveContactUs,
    path: "/ExecutiveContactUs",
    exact: true,
    wrapRoute: "publicRoute"
  },
  ExecutiveSignUp: {
    component: ExecutiveSignUp,
    path: "/ExecutiveSignUp",
    exact: true,
    wrapRoute: "publicRoute"
  },
  CoachSignup: {
    component: CoachSignup,
    path: "/CoachSignup",
    exact: true,
    wrapRoute: "publicRoute"
  },
  CaptchaCreateAccount: {
    component: CaptchaCreateAccount,
    path: "/CaptchaCreateAccount",
    exact: true,
    wrapRoute: "publicRoute"
  },
  CoachAdministration: {
    component: CoachAdministration,
    path: "/CoachAdministration",
    exact: true,
    wrapRoute: "protectedCoachForAssessment"
  },
  SurveyAssessment: {
    component: SurveyAssessmentStyle,
    path: "/SurveyAssessment",
    exact: true,
    wrapRoute: "protectedRouteForExecutive"
  },
  Home: {
    component: LandingPage,
    path: '/',
    exact: true,
    wrapRoute: "publicRoute",
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
    exact: true,
    wrapRoute: "publicRoute"
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

  NewPasswordBlock: {
    component: NewPasswordBlock,
    path: "/NewPasswordWeb",
    exact: true,
    wrapRoute: "publicRoute"
  },

  ForgotPasswordBlock: {
    component: ForgotPasswordBlock,
    path: "/ForgotPasswordBlock",
    exact: true,
    wrapRoute: "publicRoute"
  },

  Dashboard: {
    component: Dashboard,
    path: "/Dashboard",
    exact: true,
    wrapRoute: "protectedCoachForAssessment"
  },
  DashboardHome: {
    component: DashboardHome,
    path: "/DashboardHome",
    exact: true,
    wrapRoute: "protectedRouteForCoach"
  },

  SurveyResult: {
    component: SurveyResult,
    path: "/SurveyResult",
    exact: true,
    wrapRoute: "publicRoute"
  },
  ExecutiveAssessment: {
    component: ExecutiveAssessment,
    path: "/ExecutiveAssessment",
    exact: true,
    wrapRoute: "publicRoute"
  },

  AssessmentAfterAcceptance: {
    component: AssessmentAfterAcceptance,
    path: "/AssessmentAfterAcceptance",
    exact: true,
    wrapRoute: "protectedCoachForPostAssessment"
  },
  HelpCenter: {
    component: HelpCenter,
    path: "/HelpCenter",
    exact: true,
    wrapRoute: "publicRoute"
  },
  CoachSelection: {
    component: CoachSelection,
    path: "/CoachSelection",
    exact: true,
    wrapRoute: "protectedExecutiveForAssessment"
  },
  CoachSelectionProfile: {
    component: CoachSelectionProfile,
    path: "/CoachSelectionProfile",
    exact: true,
    wrapRoute: "protectedExecutiveForAssessment"
  },
  CoachBookSession: {
    component: CoachBookSession,
    path: "/CoachBookSession",
    exact: true,
    wrapRoute: "publicRoute"
  },
  CoachingResources: {
    component: CoachingResources,
    path: "/coachingResources",
    exact: true,
    wrapRoute: "protectedRouteForCoach"
  },

  ClientDashboard: {
    component: ClientDashboard,
    path: "/ClientDashboard",
    exact: true,
    wrapRoute: "protectedRouteForExecutive"
  },

  Sessions: {
    component: Sessions,
    path: "/Sessions",
    exact: true,
    wrapRoute: "protectedRouteForExecutive"
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/packages",
    exact: true,
    wrapRoute: "protectedRouteForExecutive"
  },

  PackageDetails: {
    component: PackageDetails,
    path: "/packages/package-details",
    exact: true,
    wrapRoute: "protectedRouteForExecutive"
  },

  ClientProfile: {
    component: ClientProfile,
    path: "/UpcommingSession/ClientProfile",
    exact: true,
    wrapRoute: "protectedRouteForCoach"
  },
  UpcommingSession: {
    component: UpcommingSession,
    path: "/UpcommingSession/:id?",
    exact: true,
    wrapRoute: "protectedRouteForCoach"
  },

  RatingAndReview: {
    component: RatingAndReview,
    path: "/RatingAndReview",
    exact: true,
    wrapRoute: "protectedRouteForCoach"
  },
  ClientRatingAndReview: {
    component: ClientRatingAndReview,
    path: "/ClientRatingAndReview",
    exact: true,
    wrapRoute: "protectedRouteForExecutive"
  },
  CoachSettings: {
    component: CoachSettings,
    path: "/CoachSettings",
    exact: true,
    wrapRoute: "protectedRouteForCoach"
  },
  CoachViewProfile: {
    component: CoachViewProfile,
    path: "/CoachViewProfile",
    exact: true,
    wrapRoute: "protectedRouteForCoach"
  },
  Profile: {
    component: Profile,
    path: "/Profile",
    exact: true,
    wrapRoute: "protectedRouteForExecutive"
  },

  ClientSetting: {
    component: ClientSetting,
    path: "/ClientSetting",
    exact: true,
    wrapRoute: "protectedRouteForExecutive"
  },

  CoachDetails: {
    component: CoachDetails,
    path: "/Sessions/CoachDetails",
    exact: true,
    wrapRoute: "protectedRouteForExecutive"
  },
  SessionDetails: {
    component: SessionDetails,
    path: "/Sessions/SessionDetails",
    exact: true,
    wrapRoute: "publicRoute"
  },
  CoachClientListView: {
    component: CoachClientListView,
    path: "/CoachClientList",
    exact: true,
    wrapRoute: "protectedRouteForCoach"
  },
  CoachMyClientProfile: {
    component: CoachMyClientProfile,
    path: "/CoachClientList/MyClientProfile",
    exact: true
  },
  Reports: {
    component: ComingSoon,
    path: "/Reports",
    exact: true,
    wrapRoute: "protectedRouteForCoach"
  },
  BillingandPayment: {
    component: ComingSoon,
    path: "/BillingandPayment",
    exact: true,
    wrapRoute: "protectedRouteForCoach"
  },
  AvailabilityCalendar: {
    component: AvailabilityCalendar,
    path: "/AvailabilityCalendar",
    exact: true,
    wrapRoute: "protectedRouteForCoach"
  },
  Library: {
    component: Catalogue,
    path: "/library",
    exact: true,
    wrapRoute: "protectedRouteForExecutive"
  },
  ClientChat: {
    component: ComingSoon,
    path: "/ClientChat",
    exact: true,
    wrapRoute: "protectedRouteForExecutive"
  },
  ClientBillingandPayment: {
    component: ComingSoon,
    path: "/ClientBillingandPayment",
    exact: true,
    wrapRoute: "protectedRouteForExecutive"
  },
  TakeNewSurvey: {
    component: TakeNewSurvey,
    path: "/TakeNewSurvey",
    exact: true,
    wrapRoute: "protectedRouteForExecutive",
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100%' }}>
        <Suspense fallback={<Progressbar />}>
        {/* <TopNav /> */}
        <Portal>
          <ToastContainer
            autoClose={3000}
            position="top-right"
            className="toast-container"
            toastClassName="dark-toast"
          />
        </Portal>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
        </Suspense>
      </View>
    );
  }
}

export default App;