export const details = require("./lopng.png");
export const fimg = require("./fimg.svg");
export const imgtwo = require("./2img.svg");
export const imgthree = require("./3img.svg");
export const imgfour = require("./4img.svg");
export const imgfive = require("./5img.svg");
export const imgsix = require("./6img.svg");
export const imgseven = require("./7img.svg");
export const imgeight = require("./8img.svg");
export const imgnine = require("./9img.svg");
export const logoa = require("./logoa.svg");
export const fimgf = require("./fimgf.svg");
export const imgtwot = require("./2img2.svg");
export const imgthreet = require("./3img3.svg");
export const imgfourf = require("./4img4.svg");
export const imgfivef = require("./5img5.svg");
export const activeResources = require("./activeResources.svg");
export const imgsevens = require("./7img7.svg");
export const imgeighte = require("./8img8.svg");
export const imgninen = require("./9img9.svg");
export const contact = require("./contact.svg");
export const logofull = require("./logofull.svg"); 
export const image_icon = require("./image_icon.jpeg");
export const imagebutton_icon = require("./imagebutton_icon.svg");
export const ratingIcon = require("./ratingIcon.svg");
export const ratingImg = require("./ratingImg.svg");
export const closeMenuIcon = require("./closeMenuIcon.svg");
export const logoutIcon = require("./logoutIcon.svg");
export const settingsIcon = require("./settingsIcon.svg");
export const viewProfile = require("./viewProfile.svg");
export const helpCenterIcon = require("./helpCenterIcon.svg");
export const activeHomeIcon = require("./activeHomeIcon.svg");
export const menuSidebarIcon = require("./menuIcon.svg");
export const coachLogo = require("./coachLogo.png")
export const openMenuIcon = require("./openMenuIcon.svg");
export const activeChat = require("./activeChat.svg");
export const activeSession = require("./activeSession.svg");
export const activeGoalProgress = require("./activeGoalProgress.svg");
export const activeLibrary = require("./activeLibrary.svg");
export const activePayment = require("./activePayment.svg");
export const activeServeyAssessment = require("./activeServeyAssessment.svg");
export const coachyShortIcon = require("./coachyShortIcon.svg");
export const helpcenter = require("./helpCenter.png");
export const logout = require("./logout.png");
export const viewProfileIcon = require("./profile.png");
export const settings = require("./setting.png");
export const upIconTopbar = require("./UP.png");
export const downIconTopbar = require("./DOWN.png");
export const coach_home = require("./coach_home.svg");
export const clients = require("./clients.svg");
export const availability = require("./availability.svg");
export const ratingandreview = require("./ratingandreview.svg");
export const activeClients = require("./activeClients.svg");
export const activeAvailability = require("./activeAvailability.svg");
export const activeRatingReview = require("./activeRatingReview.svg");
export const activeReports = require("./activeReports.svg");
export const activeTaskImg = require("./activeTaskImg.svg");
export const packages = require("./packages.svg");
export const activePackages = require("./activePackages.svg");