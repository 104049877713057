import React, { Component } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {
  contact,
  logoa,
  fimg,
  imgtwo,
  imgthree,
  imgfour,
  imgfive,
  imgsix,
  imgseven,
  clients,
  imgnine,
  coachLogo,
  logofull,
  ratingImg,
  coach_home,
  availability,
  ratingandreview,
  activeHomeIcon,
  activeSession,
  activeResources,
  activeClients,
  activeChat,
  activeAvailability,
  activeRatingReview,
  activeGoalProgress,
  activePayment,
  activeServeyAssessment,
  activeTaskImg,
  packages,
  activePackages
} from "./assets";
import { IconButton, Box, Typography } from '@material-ui/core';
import { NavLink } from "react-router-dom";
import { Menu as MenuIcon } from '@material-ui/icons';
import { styled } from "@material-ui/core/styles";
const BoxpositionChange = styled(Box)({
  position:"relative",
  height: "100%",
  '@media (max-width: 710px)': {
    position:"absolute",zIndex:5,
  },
});
const BoxpositionChange3 = styled(Box)({
  fontFamily: "Open Sans",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "22px",
  letterSpacing: 0,
  textAlign: "center",
  color: "#FFF",
  
  '@media (max-width: 710px)': {
   position:"unset"
  },
});
const SiderbarList = styled(List)({
  "& .active .selected": {
    background: "#fff"
  },
  "& .active .selected .dashboardEmpty ": {
    color: "rgba(57, 6, 137, 1)"
  },
})
interface Props {
  clickSideBar?: any;
  expand: boolean
  onToggle: (event:boolean)=>void
}
interface MiniDrawerProps {
  clickSideBar: any;

}

interface S {
  // Customizable Area Start
  isCollapsed: boolean;
  showSidebar: boolean;
  isZindexApply: boolean;
  // Customizable Area End
}
export default class MiniDrawer extends Component<Props, S, MiniDrawerProps> {
  // class MiniDrawer extends Component<MiniDrawerProps> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isCollapsed: false,
      showSidebar: window.innerWidth >= 710,
      isZindexApply: false,
    };
  }

  handleResize = () => {
    this.setState({
      isCollapsed: window.innerWidth >= 710,
      showSidebar: window.innerWidth >= 710
    });
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    if (window.innerWidth <= 710) {
      this.handleResize()
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  toggleSidebar = () => {
    this.setState(prevState => ({
      isCollapsed: !prevState.isCollapsed,
    }));
  };
  toggleHamIcon = () => {
    this.setState(prevState => ({
      showSidebar: !prevState.showSidebar,
      isZindexApply: !prevState.isZindexApply
    }));
  }

  handleMouseEnter = () => {
    this.props.onToggle(true);
  };

  handleMouseLeave = () => {
    this.props.onToggle(false);
  };
  IconButtonstyled = styled(Box)({
    color: '#5F0AE5',
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px'
  });


  coachMenuItems = [
    { label: 'Home', link: '/DashboardHome', icon: coach_home, activeIcon: activeHomeIcon },
    { label: 'Sessions', link: '/UpcommingSession', icon: imgtwo, activeIcon: activeSession },
    { label: 'Coaching Resources', link: '/CoachingResources', icon: imgsix, activeIcon: activeResources },
    { label: 'Clients', link: '/CoachClientList', icon: clients, activeIcon: activeClients },
    { label: 'Availability', link: '/AvailabilityCalendar', icon: availability, activeIcon: activeAvailability },
    { label: 'Rating & Reviews', link: '/RatingAndReview', icon: ratingandreview, activeIcon: activeRatingReview },
    { label: 'Chat', link: '/Chat', icon: imgseven, activeIcon: activeChat },
    { label: 'Reports', link: '/Reports', icon: imgfour, activeIcon: activeGoalProgress },
    { label: 'Billing & Payment', link: '/BillingandPayment', icon: imgnine, activeIcon: activePayment }
  ];

  clientMenuItems = [
    { label: 'Home', link: '/ClientDashboard', icon: coach_home, activeIcon: activeHomeIcon },
    { label: 'Sessions', icon: imgtwo, link: "/Sessions", activeIcon: activeSession },
    { label: 'Packages', icon: packages, link: "/packages", activeIcon: activePackages },
    { label: 'Tasks', icon: imgthree, link: "/TaskList", activeIcon: activeTaskImg },
    { label: 'Goals & Progress', icon: imgfour, link: "/GoalsAndProgress", activeIcon: activeGoalProgress },
    { label: 'Surveys & Assessments', link: '/SurveyAssessment', icon: imgfive, activeIcon: activeServeyAssessment },
    { label: 'Library', link: '/library', icon: imgsix, activeIcon: activeResources },
    { label: 'Chat', link: '/ClientChat', icon: imgseven, activeIcon: activeChat },
    { label: 'Ratings & Reviews', link: '/ClientRatingAndReview', icon: ratingImg, activeIcon: activeRatingReview },
    { label: 'Billing & Payment', link: "/ClientBillingandPayment", icon: imgnine, activeIcon: activePayment }
  ];

  render() {
    const { clickSideBar } = this.props;
    const userType = localStorage.getItem('userType');
    const menuItems = userType === 'coach' ? this.coachMenuItems : this.clientMenuItems;

    return (
      <>

        {/* Burger Icon */}
        <BoxpositionChange>
          {window.innerWidth <= 710 && (
            <IconButton onClick={this.toggleHamIcon} style={{
              color: '#5F0AE5', height: "20px",
              width: 0, marginTop: "20px", paddingLeft: "20px"
            }}>
              <MenuIcon />
            </IconButton>
          )}


          {this.state.showSidebar ?
            <div style={this.props.expand ? sidebarStyles.expanded : sidebarStyles.collapsed}
              className='sidecollapesstyle'
              onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
            >
              <List style={{ display: "flex", flex: "0 0 auto" }} >

                <ListItem >
                  {this.props.expand 
                  ? <ListItemIcon style={{ margin: "auto" }}>
                    <img src={logofull} style={{ margin: "auto", width: "153px", height: "43px" }} />
                    </ListItemIcon> 
                    : <ListItemIcon ><img src={coachLogo} /></ListItemIcon>}
                </ListItem>
                

              </List>
              <div style={sidebarStyles.sidebarSections}>
              <SiderbarList>
                {menuItems.map((item, index) => (
                  <NavLink to={`${item.link}`} style={{ textDecoration: "none" }} key={index}>
                    <ListItem button style={webStylesDesign.margibottom} className='selected'>
                      <ListItemIcon>
                        {window.location.pathname.toLowerCase().includes(item.link.toLowerCase()) ? <img src={item.activeIcon} style={webStylesDesign.sideImd} /> : <img src={item.icon} style={webStylesDesign.sideImd} />}
                      </ListItemIcon>
                      <span className='dashboardEmpty' style={this.props.expand ? styles.dashboardEmpty : styles.dashboardText}>
                        {item.label}
                      </span>
                    </ListItem>
                  </NavLink>
                ))}
              </SiderbarList>
              <BoxpositionChange3>
                <Typography style={this.props.expand ? sidebarStyles.showSidebarContent : sidebarStyles.hideSidebarContent}> In case you need assistance or facing any issues, our support team happy to help.</Typography>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px", marginTop: "10px", marginBottom: "10px" }}>
                  <div><img src={contact} style={{ width: "40.25px", height: "40px" }} /></div>
                  <div style={this.props.expand ? styles.dashboardEmpty : styles.dashboardText}>Contact us</div>
                </div>
              </BoxpositionChange3>
              </div>

            </div> : ""}

        </BoxpositionChange>
      </>
    );
  }
}

const webStylesDesign: any = {
  root: {
    height: "100%",
    background: "#5F0AE5",
    width: "288px",

  },
  rootCollapsed: {
    height: "100%",
    background: "#5F0AE5",
    width: "288px",
  },
  margibottom: {
    marginBlock: "1rem",
    color: "#fff"
  },
  rootSec: {
    height: "100%",
    background: "#5F0AE5",
  },
  sideColor: {
    width: "40px",
    height: "52px",
    borderRadius: "50%",
    background: "#7F3BEA",
    margin: "auto"
  },
  sideImd: {
    width: "34px",
    height: "34px",
  },
  sideImdicon: {
    backgroundColor: "#ffffff",
    width: "30px",
    height: "30px",
    marginLeft: "4px"
  }
}
const sidebarStyles = {
  sidebarSections: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: "1 1 auto",
    gap: "20px",
  } as const,
  showSidebarContent: {
    display: "block",
    fontSize: "16px",
    fontFamily: "Open sans",
    fontWeight: 700,
    lineHeight: "21.79px",
    textAlign: "center",
    letterSpacing: 0
  } as const,
  hideSidebarContent: {
    display: "none",
  } as const,
  sideImdicon: {
    backgroundColor: "#ffffff",
    width: "30px",
    height: "30px",
    border: "2px solid white"

  },
  collapsed: {
    height: "auto",
    minHeight: "100%",
    background: "#5F0AE5",
    width: "70px",
    transition: "width 0.45s cubic-bezier(0.600, 0.040, 0.350, 1)",
    display: "flex",
    flexDirection: "column",
  } as const,
  expanded: {
    height: "auto",
    minHeight: "100%",
    background: "#5F0AE5",
    width: "288px",
    transition: "width 0.45s cubic-bezier(0.600, 0.040, 0.350, 1)",
    display: "flex",
    flexDirection: "column",
  } as const, 

};
const styles = {
  dashboardEmpty: {
    fontSize: "15px",
    display: "contents"
  },
  dashboardText: {
    display: 'none' 
  }
};


// export default MiniDrawer;
