Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.commonBgColor = "#EDEFEE"
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.getAssessementMethod = "GET";
exports.postAssessementMethod = "POST";
exports.patchAssessementMethod = "PATCH";
exports.putAssessementMethod = "PUT";
exports.deleteAssessementMethod = "DELETE";
exports.updateCoachAPiurl = "bx_block_surveys/coach_professional_backgrounds";
exports.allAssessementTestApiContentType = "application/json";
exports.getAllAssessementTestUrl = `bx_block_assessmenttest/assessments`;
exports.getAssessementTestCategoriesUrl = `bx_block_assessmenttest/assessment_types`;
exports.getAttemptedAssessmentsUrl = `bx_block_assessmenttest/user_all_assessments`;
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AssessmentTest";
exports.labelBodyText = "AssessmentTest Body";
exports.btnExampleTitle = "CLICK ME";
exports.mockPassword = "password!";
exports.MainHeading = "Survey & Assessments"
exports.HomeText = "Home"
exports.ButtonText = "Take New Survey"
exports.Surveys = "Surveys"
exports.Assessments = "Assessments"
exports.SurveysResults = "Surveys Results"
exports.RecommendedCoach = "Recommended Coach"
exports.topCoach = "Your top Coach matches"
exports.topCoachselect = "Select the option that seems to be the most suitable match for you."
exports.Certificate = "Certificate"
exports.star = "*"
exports.linkdlin = "LinkedIn URL"
exports.Optional = "Optional"
exports.errorMsg = "Please select certificate to continue"
exports.fileError="File size exceeds the maximum limit. Please upload a smaller file"
exports.uploadError="File size exceeds the maximum limit. Please upload a smaller file"
exports.textareaError="This field is required. Please fill it out."
exports.LinkdinError="This field is required."
exports.Topfit="Top fit"
exports.years="years"
exports.showMore="Show More"
exports.pending="Coming Soon"
exports.pendingtitle="Exciting updates are in progress!"
exports.pendingSubtitle="Stay tuned, this feature is on its way"
exports.recommededCoachAPiurl="bx_block_profile/profiles/coach_profiles"
exports.surveyAPiurl="bx_block_surveys/surveys/get_survey_list"
exports.surveyResultsAPiurl="bx_block_surveys/surveys/executive_survey_results"
exports.fieldRequiredError = "This field is required. Please fill it out.";


exports.getAssessmentListEndPoint= "bx_block_assessmenttest/client_assessments";
exports.getAssessmentListMethod="GET";
exports.getAssessmentListContentType="application/json";
exports.updateAssessmentEndPoint= "bx_block_assessmenttest/client_assessments";
exports.updateAssessmentMethod="PATCH";
exports.updateAssessmentContentType="application/json";
exports.getSingleAssessmentEndPoint= "bx_block_assessmenttest/client_assessments";
exports.getSingleAssessmentMethod="GET";
exports.getSingleAssessmentContentType="application/json";
exports.uploadResultEndPoint= "bx_block_assessmenttest/client_assessments/submit_assessment_by_client";
exports.uploadResultMethod="PUT";
exports.showLess="Show Less";
exports.mockAssessmentData = {
  client_assessments: {
    data: [
      {
        id: "8",
        type: "client_assessment",
        attributes: {
          id: 8,
          assessment_id: 25,
          assessment_name: "Talentoday",
          client_id: 1165,
          client_name: "Raj",
          coach_id: 499,
          coach_profile_pic:
            "https://test/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmtWIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--231484f631b358361fc443e5c47140befa9d7546/pexels-cottonbro-studio-3201694%20(1).jpg",
          coach_name: "Ashish",
          start_date: "2024-08-01",
          due_date: "2024-08-02",
          status: "completed",
          result_status: "uploaded",
          result:
            "https://test/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcVVjIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--55aab1d13c4d84878dadd8cb302e65cd13519631/file-sample_150kB.pdf",
          description: "Test",
          file_size: "139.44KB",
        },
      },
      {
        id: "3",
        type: "client_assessment",
        attributes: {
          id: 3,
          assessment_id: 15,
          assessment_name: "Thomas-Kilmann Conflict Mode Instrument (TKI)",
          client_id: 1165,
          client_name: "Raj",
          coach_id: 499,
          coach_profile_pic:
            "https://test/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmtWIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--231484f631b358361fc443e5c47140befa9d7546/pexels-cottonbro-studio-3201694%20(1).jpg",
          coach_name: "Ashish",
          start_date: "2024-07-24",
          due_date: "2024-07-31",
          status: "completed",
          result_status: "uploaded",
          result:
            "https://test/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBc29jIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9706d6cf470a637a968179ea77eb92d1c882583e/file-sample_150kB.pdf",
          description: "Test",
          file_size: "139.44KB",
        },
      },
      {
        id: "2",
        type: "client_assessment",
        attributes: {
          id: 2,
          assessment_id: 12,
          assessment_name: "DISC Assessment",
          client_id: 1165,
          client_name: "Raj",
          coach_id: 499,
          coach_profile_pic:
            "https://test/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmtWIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--231484f631b358361fc443e5c47140befa9d7546/pexels-cottonbro-studio-3201694%20(1).jpg",
          coach_name: "Ashish",
          start_date: "2024-07-19",
          due_date: "2024-07-20",
          status: "in_progress",
          result_status: "pending",
          result: null,
          description: "Test",
          file_size: null,
        },
      },
      {
        id: "6",
        type: "client_assessment",
        attributes: {
          id: 6,
          assessment_id: 14,
          assessment_name: "SHL Occupational Personality Questionnaire (OPQ)",
          client_id: 1165,
          client_name: "Raj",
          coach_id: 499,
          coach_profile_pic:
            "https://test/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmtWIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--231484f631b358361fc443e5c47140befa9d7546/pexels-cottonbro-studio-3201694%20(1).jpg",
          coach_name: "Ashish",
          start_date: "2024-07-19",
          due_date: "2024-07-20",
          status: "in_progress",
          result_status: "pending",
          result: null,
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently wit.",
          file_size: null,
        },
      },
      {
        id: "4",
        type: "client_assessment",
        attributes: {
          id: 4,
          assessment_id: 12,
          assessment_name: "DISC Assessment",
          client_id: 1165,
          client_name: "Raj",
          coach_id: 499,
          coach_profile_pic:
            "https://test/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmtWIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--231484f631b358361fc443e5c47140befa9d7546/pexels-cottonbro-studio-3201694%20(1).jpg",
          coach_name: "Ashish",
          start_date: "2024-07-13",
          due_date: "2024-07-14",
          status: "in_progress",
          result_status: "pending",
          result: null,
          description: "Test....",
          file_size: null,
        },
      },
      {
        id: "5",
        type: "client_assessment",
        attributes: {
          id: 5,
          assessment_id: 15,
          assessment_name: "Thomas-Kilmann Conflict Mode Instrument (TKI)",
          client_id: 1165,
          client_name: "Raj",
          coach_id: 499,
          coach_profile_pic:
            "https://test/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmtWIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--231484f631b358361fc443e5c47140befa9d7546/pexels-cottonbro-studio-3201694%20(1).jpg",
          coach_name: "Ashish",
          start_date: "2024-07-11",
          due_date: "2024-07-15",
          status: "in_progress",
          result_status: "pending",
          result: null,
          description: "Testing Testing Testing Testing Testing Testing ",
          file_size: null,
        },
      },
      {
        id: "7",
        type: "client_assessment",
        attributes: {
          id: 7,
          assessment_id: 13,
          assessment_name: "CliftonStrengths (formerly StrengthsQuest)",
          client_id: 1165,
          client_name: "Raj",
          coach_id: 499,
          coach_profile_pic:
            "https://test/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmtWIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--231484f631b358361fc443e5c47140befa9d7546/pexels-cottonbro-studio-3201694%20(1).jpg",
          coach_name: "Ashish",
          start_date: "2024-07-04",
          due_date: "2024-07-04",
          status: "assigned",
          result_status: "pending",
          result: null,
          description: "Test",
          file_size: null,
        },
      },
    ],
  },
};
exports.profileMockData = {
  data: {
    id: "499",
    type: "email_account",
    attributes: {
      id: 499,
      full_name: "Ashish",
      user_type: "executive",
      availability_status: true,
      gender: "male",
      country_name: "India",
      country_code: 91,
      city: null,
      country_flag: "🇮🇳",
      phone_number: 7676567567,
      full_phone_number: "917676567567",
      email: "ashish.coach05@yopmail.com",
      coach_profile_status: "accepted_available",
      coach_languages: ["English", "Arabic", "Gujarati"],
      user_bio: null,
      date_of_birth: "2006-01-13",
      activated: true,
      profile_pic: "https://test/sbucket/x13g72f9fiengrbl1ha9wkc7400v",
      introduction_video: "https://test/sbucket/8ud989wfjbjnhyeuf42iuq6tp9jw",
      preferred_coaching_language: [],
    },
  },
};
exports.executiveSurveyQuestion = {
  data: [
    {
      id: "418",
      type: "executive_questions",
      attributes: {
        id: 418,
        question: "Have you tried coaching before?",
        select_type: "type1",
        include_other: "no",
        question_type: "required",
        video: null,
        options: [
          {
            id: 2485,
            option_text: "Yes",
            executive_question_id: 418,
            created_at: "2024-06-06T12:53:29.552Z",
            updated_at: "2024-06-06T12:53:29.552Z",
          },
          {
            id: 2486,
            option_text: "I am new to coaching",
            executive_question_id: 418,
            created_at: "2024-06-06T12:53:29.560Z",
            updated_at: "2024-06-06T12:53:29.560Z",
          },
        ],
      },
    },
    {
      id: "419",
      type: "executive_questions",
      attributes: {
        id: 419,
        question:
          "Please check out this video before you dive into the rest of this survey.",
        select_type: "video",
        include_other: "no",
        question_type: "required",
        video: "https://test/sbucket/8avvf0jm9re8bflog77cizg8j8hr",
        options: [],
      },
    },
    {
      id: "420",
      type: "executive_questions",
      attributes: {
        id: 420,
        question: "What would you like the coach to help you with?",
        select_type: "type1",
        include_other: "no",
        question_type: "required",
        video: null,
        options: [
          {
            id: 2487,
            option_text:
              "A partner to accelerate my professional performance and growth ",
            executive_question_id: 420,
            created_at: "2024-06-06T12:53:29.594Z",
            updated_at: "2024-06-06T12:53:29.594Z",
          },
          {
            id: 2488,
            option_text: "Achieve clarity and do more with my life ",
            executive_question_id: 420,
            created_at: "2024-06-06T12:53:29.601Z",
            updated_at: "2024-06-06T12:53:29.601Z",
          },
        ],
      },
    },
    {
      id: "421",
      type: "executive_questions",
      attributes: {
        id: 421,
        question: "Are there any specific preferences for your coach?",
        select_type: "type2",
        include_other: "no",
        question_type: "required",
        video: null,
        options: [
          {
            id: 2489,
            option_text: "Male",
            executive_question_id: 421,
            created_at: "2024-06-06T12:53:29.618Z",
            updated_at: "2024-06-06T12:53:29.618Z",
          },
          {
            id: 2490,
            option_text: "Female",
            executive_question_id: 421,
            created_at: "2024-06-06T12:53:29.624Z",
            updated_at: "2024-06-06T12:53:29.624Z",
          },
          {
            id: 2491,
            option_text: "Senior coach",
            executive_question_id: 421,
            created_at: "2024-06-06T12:53:29.630Z",
            updated_at: "2024-06-06T12:53:29.630Z",
          },
          {
            id: 2492,
            option_text: "Local coach",
            executive_question_id: 421,
            created_at: "2024-06-06T12:53:29.637Z",
            updated_at: "2024-06-06T12:53:29.637Z",
          },
          {
            id: 2493,
            option_text: "Subject matter expert",
            executive_question_id: 421,
            created_at: "2024-06-06T12:53:29.646Z",
            updated_at: "2024-06-06T12:53:29.646Z",
          },
          {
            id: 2494,
            option_text: "I am open to all options",
            executive_question_id: 421,
            created_at: "2024-06-06T12:53:29.652Z",
            updated_at: "2024-06-06T12:53:29.652Z",
          },
        ],
      },
    },
    {
      id: "422",
      type: "executive_questions",
      attributes: {
        id: 422,
        question: "What category do you belong to?",
        select_type: "type3",
        include_other: "yes",
        question_type: "required",
        video: null,
        options: [
          {
            id: 2495,
            option_text: "Sales/Marketing",
            executive_question_id: 422,
            created_at: "2024-06-06T12:53:29.666Z",
            updated_at: "2024-06-06T12:53:29.666Z",
          },
          {
            id: 2496,
            option_text: "Operations/Production",
            executive_question_id: 422,
            created_at: "2024-06-06T12:53:29.673Z",
            updated_at: "2024-06-06T12:53:29.673Z",
          },
          {
            id: 2497,
            option_text: "Human Resources",
            executive_question_id: 422,
            created_at: "2024-06-06T12:53:29.682Z",
            updated_at: "2024-06-06T12:53:29.682Z",
          },
          {
            id: 2498,
            option_text: "Communication \u0026 PR",
            executive_question_id: 422,
            created_at: "2024-06-06T12:53:29.690Z",
            updated_at: "2024-06-06T12:53:29.690Z",
          },
          {
            id: 2499,
            option_text: "Information Technology",
            executive_question_id: 422,
            created_at: "2024-06-06T12:53:29.698Z",
            updated_at: "2024-06-06T12:53:29.698Z",
          },
          {
            id: 2500,
            option_text: "Customer Service",
            executive_question_id: 422,
            created_at: "2024-06-06T12:53:29.705Z",
            updated_at: "2024-06-06T12:53:29.705Z",
          },
          {
            id: 2501,
            option_text: "Legal",
            executive_question_id: 422,
            created_at: "2024-06-06T12:53:29.714Z",
            updated_at: "2024-06-06T12:53:29.714Z",
          },
          {
            id: 2502,
            option_text: "Compliance",
            executive_question_id: 422,
            created_at: "2024-06-06T12:53:29.723Z",
            updated_at: "2024-06-06T12:53:29.723Z",
          },
          {
            id: 2503,
            option_text: "Supply chain \u0026 Logistics",
            executive_question_id: 422,
            created_at: "2024-06-06T12:53:29.731Z",
            updated_at: "2024-06-06T12:53:29.731Z",
          },
          {
            id: 2504,
            option_text: "Procurement or Purchasing",
            executive_question_id: 422,
            created_at: "2024-06-06T12:53:29.740Z",
            updated_at: "2024-06-06T12:53:29.740Z",
          },
          {
            id: 2505,
            option_text: "Healthcare",
            executive_question_id: 422,
            created_at: "2024-06-06T12:53:29.749Z",
            updated_at: "2024-06-06T12:53:29.749Z",
          },
          {
            id: 2506,
            option_text: "Project Management",
            executive_question_id: 422,
            created_at: "2024-06-06T12:53:29.756Z",
            updated_at: "2024-06-06T12:53:29.756Z",
          },
          {
            id: 2507,
            option_text: "QA/QC",
            executive_question_id: 422,
            created_at: "2024-06-06T12:53:29.765Z",
            updated_at: "2024-06-06T12:53:29.765Z",
          },
          {
            id: 2508,
            option_text: "Others",
            executive_question_id: 422,
            created_at: "2024-06-06T12:53:29.770Z",
            updated_at: "2024-06-06T12:53:29.770Z",
          },
        ],
      },
    },
    {
      id: "423",
      type: "executive_questions",
      attributes: {
        id: 423,
        question: "What is your career level?",
        select_type: "type4",
        include_other: "no",
        question_type: "required",
        video: null,
        options: [
          {
            id: 2509,
            option_text: "Board Membership",
            executive_question_id: 423,
            created_at: "2024-06-06T12:53:29.785Z",
            updated_at: "2024-06-06T12:53:29.785Z",
          },
          {
            id: 2510,
            option_text: "Executive Leadership. C-Level, CXOs",
            executive_question_id: 423,
            created_at: "2024-06-06T12:53:29.794Z",
            updated_at: "2024-06-06T12:53:29.794Z",
          },
          {
            id: 2511,
            option_text: "Senior Leadership",
            executive_question_id: 423,
            created_at: "2024-06-06T12:53:29.803Z",
            updated_at: "2024-06-06T12:53:29.803Z",
          },
          {
            id: 2512,
            option_text: "Middle Management",
            executive_question_id: 423,
            created_at: "2024-06-06T12:53:29.811Z",
            updated_at: "2024-06-06T12:53:29.811Z",
          },
          {
            id: 2513,
            option_text: "First-Line Management",
            executive_question_id: 423,
            created_at: "2024-06-06T12:53:29.818Z",
            updated_at: "2024-06-06T12:53:29.818Z",
          },
          {
            id: 2514,
            option_text: "Team Leadership",
            executive_question_id: 423,
            created_at: "2024-06-06T12:53:29.827Z",
            updated_at: "2024-06-06T12:53:29.827Z",
          },
          {
            id: 2515,
            option_text: "Individual Contributor/Specialist",
            executive_question_id: 423,
            created_at: "2024-06-06T12:53:29.836Z",
            updated_at: "2024-06-06T12:53:29.836Z",
          },
          {
            id: 2516,
            option_text: "Entrepreneur",
            executive_question_id: 423,
            created_at: "2024-06-06T12:53:29.846Z",
            updated_at: "2024-06-06T12:53:29.846Z",
          },
          {
            id: 2517,
            option_text: "Freelancer, part time",
            executive_question_id: 423,
            created_at: "2024-06-06T12:53:29.853Z",
            updated_at: "2024-06-06T12:53:29.853Z",
          },
          {
            id: 2518,
            option_text: "In transition",
            executive_question_id: 423,
            created_at: "2024-06-06T12:53:29.860Z",
            updated_at: "2024-06-06T12:53:29.860Z",
          },
        ],
      },
    },
    {
      id: "424",
      type: "executive_questions",
      attributes: {
        id: 424,
        question:
          "Are you ready to invest the time it takes to get the most out of your experience?",
        select_type: "type1",
        include_other: "no",
        question_type: "required",
        video: null,
        options: [
          {
            id: 2519,
            option_text:
              "Yes, I have been thinking about this for a while now.",
            executive_question_id: 424,
            created_at: "2024-06-06T12:53:29.875Z",
            updated_at: "2024-06-06T12:53:29.875Z",
          },
          {
            id: 2520,
            option_text: "I believe so. The idea of coaching is new to me.",
            executive_question_id: 424,
            created_at: "2024-06-06T12:53:29.885Z",
            updated_at: "2024-06-06T12:53:29.885Z",
          },
          {
            id: 2521,
            option_text: "I’m not sure.",
            executive_question_id: 424,
            created_at: "2024-06-06T12:53:29.896Z",
            updated_at: "2024-06-06T12:53:29.896Z",
          },
        ],
      },
    },
    {
      id: "425",
      type: "executive_questions",
      attributes: {
        id: 425,
        question: "Who referred you to Coachy.sa?",
        select_type: "type5",
        include_other: "no",
        question_type: "required",
        video: null,
        options: [
          {
            id: 2522,
            option_text: "Google search",
            executive_question_id: 425,
            created_at: "2024-06-06T12:53:29.916Z",
            updated_at: "2024-06-06T12:53:29.916Z",
          },
          {
            id: 2523,
            option_text: "LinkedIn",
            executive_question_id: 425,
            created_at: "2024-06-06T12:53:29.925Z",
            updated_at: "2024-06-06T12:53:29.925Z",
          },
          {
            id: 2524,
            option_text: "YouTube",
            executive_question_id: 425,
            created_at: "2024-06-06T12:53:29.935Z",
            updated_at: "2024-06-06T12:53:29.935Z",
          },
          {
            id: 2525,
            option_text: "Someone I know",
            executive_question_id: 425,
            created_at: "2024-06-06T12:53:29.946Z",
            updated_at: "2024-06-06T12:53:29.946Z",
          },
          {
            id: 2526,
            option_text: "Social media",
            executive_question_id: 425,
            created_at: "2024-06-06T12:53:29.957Z",
            updated_at: "2024-06-06T12:53:29.957Z",
          },
          {
            id: 2527,
            option_text: "Influencer",
            executive_question_id: 425,
            created_at: "2024-06-06T12:53:29.967Z",
            updated_at: "2024-06-06T12:53:29.967Z",
          },
          {
            id: 2528,
            option_text: "Others",
            executive_question_id: 425,
            created_at: "2024-06-06T12:53:29.976Z",
            updated_at: "2024-06-06T12:53:29.976Z",
          },
        ],
      },
    },
    {
      id: "426",
      type: "executive_questions",
      attributes: {
        id: 426,
        question:
          "Which of the following would you like to cover first with a Coach?",
        select_type: "type6",
        include_other: "no",
        question_type: "required",
        video: null,
        options: [
          {
            id: 2529,
            option_text:
              "\u003cp\u003e\u003cstrong\u003eCareer development:\u003c/strong\u003e take ownership of your career path or navigate a transition.\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-06T12:53:29.994Z",
            updated_at: "2024-06-06T12:53:29.994Z",
          },
          {
            id: 2530,
            option_text:
              "\u003cp\u003e\u003cstrong\u003eLeading others:\u003c/strong\u003e deepen your ability to inspire and develop those around you.\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-06T12:53:30.002Z",
            updated_at: "2024-06-06T12:53:30.002Z",
          },
          {
            id: 2531,
            option_text:
              "\u003cp\u003e\u003cstrong\u003eStrategic management:\u003c/strong\u003e improve strategic decision making and manage complex projects.\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-06T12:53:30.012Z",
            updated_at: "2024-06-06T12:53:30.012Z",
          },
          {
            id: 2532,
            option_text:
              "\u003cp\u003e\u003cstrong\u003eInfluence:\u003c/strong\u003e enhance your executive presence and realm of influence.\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-06T12:53:30.022Z",
            updated_at: "2024-06-06T12:53:30.022Z",
          },
          {
            id: 2533,
            option_text:
              "\u003cp\u003e\u003cstrong\u003eTime management and productivity:\u003c/strong\u003e increase focus and productivity to drive maximum impact.\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-06T12:53:30.033Z",
            updated_at: "2024-06-06T12:53:30.033Z",
          },
          {
            id: 2534,
            option_text:
              "\u003cp\u003e\u003cstrong\u003eCommunication:\u003c/strong\u003e effectively communicate to audiences large and small.\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-06T12:53:30.040Z",
            updated_at: "2024-06-06T12:53:30.040Z",
          },
          {
            id: 2535,
            option_text:
              "\u003cp\u003e\u003cstrong\u003eWell-being:\u003c/strong\u003e improve how you care for your physical and emotional self.\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-06T12:53:30.046Z",
            updated_at: "2024-06-06T12:53:30.046Z",
          },
          {
            id: 2536,
            option_text:
              "\u003cp\u003e\u003cstrong\u003eVision:\u003c/strong\u003e Crafting an organization vision and cascading it down.\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-06T12:53:30.056Z",
            updated_at: "2024-06-06T12:53:30.056Z",
          },
          {
            id: 2537,
            option_text:
              "\u003cp\u003e\u003cstrong\u003eAccountability:\u003c/strong\u003e Taking accountability for my actions and decisions\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-06T12:53:30.063Z",
            updated_at: "2024-06-06T12:53:30.063Z",
          },
          {
            id: 2538,
            option_text:
              "\u003cp\u003e\u003cstrong\u003eFeedback:\u003c/strong\u003e Openness to receiving feedback and working with input from others\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-06T12:53:30.073Z",
            updated_at: "2024-06-06T12:53:30.073Z",
          },
          {
            id: 2539,
            option_text:
              "\u003cp\u003e\u003cstrong\u003eMarket knowledge:\u003c/strong\u003e Understanding the industry and dynamics influencing my business\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-06T12:53:30.080Z",
            updated_at: "2024-06-06T12:53:30.080Z",
          },
          {
            id: 2540,
            option_text:
              "\u003cp\u003e\u003cstrong\u003ePeople Development:\u003c/strong\u003e Adopt strategies to hire and develop the right talent within my organization\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-06T12:53:30.089Z",
            updated_at: "2024-06-06T12:53:30.089Z",
          },
          {
            id: 2541,
            option_text:
              "\u003cp\u003e\u003cstrong\u003eResourcefulness:\u003c/strong\u003e Developing my ability to utilize resources and deploy the right tools\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-06T12:53:30.096Z",
            updated_at: "2024-06-06T12:53:30.096Z",
          },
          {
            id: 2542,
            option_text:
              "\u003cp\u003e\u003cstrong\u003eStrategy:\u003c/strong\u003e Developing business strategies and balancing long and short terms actions\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-06T12:53:30.105Z",
            updated_at: "2024-06-06T12:53:30.105Z",
          },
          {
            id: 2543,
            option_text:
              "\u003cp\u003e\u003cstrong\u003eRisk:\u003c/strong\u003e Governing, managing and mitigating risks within my area of responsibility\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-06T12:53:30.113Z",
            updated_at: "2024-06-06T12:53:30.113Z",
          },
          {
            id: 2544,
            option_text:
              "\u003cp\u003e\u003cstrong\u003eCulture:\u003c/strong\u003e Fostering and organization culture\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-06T12:53:30.122Z",
            updated_at: "2024-06-06T12:53:30.122Z",
          },
          {
            id: 2545,
            option_text:
              "\u003cp\u003e\u003cstrong\u003eCreativity \u0026 innovation:\u003c/strong\u003e Creating an innovative and creative organization and teams\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-06T12:53:30.132Z",
            updated_at: "2024-06-06T12:53:30.132Z",
          },
          {
            id: 2552,
            option_text:
              "\u003cp\u003e\u003cstrong\u003eCulture:\u003c/strong\u003e Fostering and organization culture\u003c/p\u003e\r\n",
            executive_question_id: 426,
            created_at: "2024-06-07T05:55:42.331Z",
            updated_at: "2024-06-07T05:55:42.331Z",
          },
        ],
      },
    },
    {
      id: "427",
      type: "executive_questions",
      attributes: {
        id: 427,
        question: "Which coaching style works best for you?",
        select_type: "type2",
        include_other: "no",
        question_type: "required",
        video: null,
        options: [
          {
            id: 2546,
            option_text: "Motivational",
            executive_question_id: 427,
            created_at: "2024-06-06T12:53:30.148Z",
            updated_at: "2024-06-06T12:53:30.148Z",
          },
          {
            id: 2547,
            option_text: "Direct",
            executive_question_id: 427,
            created_at: "2024-06-06T12:53:30.157Z",
            updated_at: "2024-06-06T12:53:30.157Z",
          },
          {
            id: 2548,
            option_text: "Calm",
            executive_question_id: 427,
            created_at: "2024-06-06T12:53:30.165Z",
            updated_at: "2024-06-06T12:53:30.165Z",
          },
          {
            id: 2549,
            option_text: "Upbeat",
            executive_question_id: 427,
            created_at: "2024-06-06T12:53:30.172Z",
            updated_at: "2024-06-06T12:53:30.172Z",
          },
          {
            id: 2550,
            option_text: "Collaborative",
            executive_question_id: 427,
            created_at: "2024-06-06T12:53:30.181Z",
            updated_at: "2024-06-06T12:53:30.181Z",
          },
          {
            id: 2551,
            option_text: "I am not sure",
            executive_question_id: 427,
            created_at: "2024-06-06T12:53:30.188Z",
            updated_at: "2024-06-06T12:53:30.188Z",
          },
        ],
      },
    },
    {
      id: "428",
      type: "executive_questions",
      attributes: {
        id: 428,
        question:
          "The more we know about what you’d like to get out of coaching, the better. So, tell us, what do you want to accomplish in the short term?",
        select_type: "type7",
        include_other: "no",
        question_type: "optional",
        video: null,
        options: [],
      },
    },
  ],
};
exports.singleSurveyAnswer = {
  message: [
    {
      questions:
        "The more we know about what you’d like to get out of coaching, the better. So, tell us, what do you want to accomplish in the short term?",
      answers: [""],
    },
    {
      questions: "Which coaching style works best for you?",
      answers: ["Motivational"],
    },
    {
      questions:
        "Which of the following would you like to cover first with a Coach?",
      answers: [
        "\u003cp\u003e\u003cstrong\u003eCareer development:\u003c/strong\u003e take ownership of your career path or navigate a transition.\u003c/p\u003e\r\n",
      ],
    },
    { questions: "Who referred you to Coachy.sa?", answers: ["Google search"] },
    {
      questions:
        "Are you ready to invest the time it takes to get the most out of your experience?",
      answers: ["Yes, I have been thinking about this for a while now."],
    },
    { questions: "What is your career level?", answers: ["Board Membership"] },
    {
      questions: "What category do you belong to?",
      answers: ["Customer Service"],
    },
    {
      questions: "Are there any specific preferences for your coach?",
      answers: ["Male", "Female", "Senior coach", "Local coach"],
    },
    {
      questions: "What would you like the coach to help you with?",
      answers: ["Achieve clarity and do more with my life "],
    },
    {
      questions:
        "Please check out this video before you dive into the rest of this survey.",
      answers: [],
    },
    {
      questions: "Have you tried coaching before?",
      answers: ["I am new to coaching"],
    },
  ],
};
exports.recommendedCoachData = {
  data: [
    {
      id: "1035",
      type: "top_fit_coach",
      attributes: {
        id: 1035,
        first_name: null,
        last_name: null,
        full_name: "Neeraj MVP",
        user_bio: null,
        top_flag: false,
        experience: 10,
        profile_pic:
          "https://test/sbucket/9p4o1x5w1rsuip7edlseg5h6fn8e",
        qualifications: {
          data: {
            id: "1232",
            type: "coach_qualifications",
            attributes: {
              id: 1232,
              certificate_number: "Neeraj001",
              account_id: 1035,
              certification_id: 1,
              organization_id: 1,
              organization_name: "Organization 1",
              certificate_name: "Cert 1",
              document:
                "https://test/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdUFaIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--bf4975a0a2fa7b7081829381978859134e07d6af/End%20user%20sprint%209.docx.pdf",
            },
          },
        },
      },
    },
    {
      id: "499",
      type: "top_fit_coach",
      attributes: {
        id: 499,
        first_name: null,
        last_name: null,
        full_name: "Ashish",
        user_bio: null,
        top_flag: false,
        experience: 1,
        profile_pic:
          "https://test/sbucket/x13g72f9fiengrbl1ha9wkc7400v",
        qualifications: {
          data: {
            id: "1087",
            type: "coach_qualifications",
            attributes: {
              id: 1087,
              certificate_number: null,
              account_id: 499,
              certification_id: 1,
              organization_id: 1,
              organization_name: "Organization 1",
              certificate_name: "Cert 1",
              document:
                "https://test/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbmNWIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--8036217724193dc4738b73772ff4af7d9e736452/sample.pdf",
            },
          },
        },
      },
    },
    {
      id: "1140",
      type: "top_fit_coach",
      attributes: {
        id: 1140,
        first_name: null,
        last_name: null,
        full_name: "Ishan",
        user_bio: null,
        top_flag: false,
        experience: 7,
        profile_pic:
          "https://test/sbucket/ok91fucm3sm2f0pw3ilxwh7oh8vk",
        qualifications: {
          data: {
            id: "1338",
            type: "coach_qualifications",
            attributes: {
              id: 1338,
              certificate_number: "988",
              account_id: 1140,
              certification_id: 29,
              organization_id: 17,
              organization_name: "organization2",
              certificate_name: "cert4",
              document:
                "https://test/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBc1FjIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b02b0e61447d9526f667ac1ec004583b8f63b4cd/file-sample_1MB.doc",
            },
          },
        },
      },
    },
    {
      id: "1171",
      type: "top_fit_coach",
      attributes: {
        id: 1171,
        first_name: null,
        last_name: null,
        full_name: "Shiva",
        user_bio: null,
        top_flag: false,
        experience: 0,
        profile_pic:
          "https://test/sbucket/t1nzhy21x872amiugv77ro9sshs5",
        qualifications: {
          data: {
            id: "1330",
            type: "coach_qualifications",
            attributes: {
              id: 1330,
              certificate_number: null,
              account_id: 1171,
              certification_id: 1,
              organization_id: 1,
              organization_name: "Organization 1",
              certificate_name: "Cert 1",
              document:
                "https://test/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb0VjIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b1e28617784f5eb6bab50e57fe495ffcff0e3f32/sample.pdf",
            },
          },
        },
      },
    },
    {
      id: "1172",
      type: "top_fit_coach",
      attributes: {
        id: 1172,
        first_name: null,
        last_name: null,
        full_name: "Abhi",
        user_bio: null,
        top_flag: false,
        experience: 0,
        profile_pic:
          "https://test/sbucket/bji7bxiyv75d5reqqnrb274lc9xb",
        qualifications: {
          data: {
            id: "1331",
            type: "coach_qualifications",
            attributes: {
              id: 1331,
              certificate_number: null,
              account_id: 1172,
              certification_id: 31,
              organization_id: 17,
              organization_name: "organization2",
              certificate_name: "cert5",
              document:
                "https://test/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb2djIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f4afb390a20a68d9e6e38d9eccca82053eea2626/sample.pdf",
            },
          },
        },
      },
    },
    {
      id: "1174",
      type: "top_fit_coach",
      attributes: {
        id: 1174,
        first_name: null,
        last_name: null,
        full_name: "Habibi",
        user_bio: null,
        top_flag: false,
        experience: 6,
        profile_pic:
          "https://test/sbucket/7g73haqj7pow459bfgfrm7qtleqs",
        qualifications: {
          data: {
            id: "1333",
            type: "coach_qualifications",
            attributes: {
              id: 1333,
              certificate_number: "RE43242",
              account_id: 1174,
              certification_id: 1,
              organization_id: 1,
              organization_name: "Organization 1",
              certificate_name: "Cert 1",
              document:
                "https://test/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcUFjIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ca495ccabdbf36abacbf2512f1179519d1c7a3aa/file-sample_150kB.pdf",
            },
          },
        },
      },
    },
  ],
};
  // Customizable Area End
