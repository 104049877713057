import React from "react";
import {
    styled,
    Modal,
    Typography,
    Grid,
    Box
} from "@material-ui/core";
import { exitImgLogout } from "../../blocks/dashboard/src/assets";

interface Props {
    showLogoutModal: boolean;
    logoutModalClose: () => void;
    logoutUser: () => void;
}

function LogoutModal({ showLogoutModal, logoutModalClose, logoutUser }: Props) {
    return (
        <Modal
            open={showLogoutModal}
            onClose={logoutModalClose}
        >
            <ModalBody>
                <Typography className="modalTitle">
                    Logout
                </Typography>
                <Grid container spacing={3} className="exitLogoutStyle" justifyContent="center">
                    <Grid >
                        <StyledImgBox>  <img src={exitImgLogout} alt="logout" /></StyledImgBox>
                    </Grid>
                    <Grid container justifyContent="center" className="logoutText">
                        <StyledHeadingBox>Are you sure want to logout ?</StyledHeadingBox>
                    </Grid>
                </Grid>
                <Grid item md={12} container spacing={3} justifyContent="center" className="allButton">
                    <Grid item md={6}>
                        <ModalBodyCancel data-test-id="closeLogoutModal" onClick={logoutModalClose}>Cancel</ModalBodyCancel>
                    </Grid>
                    <Grid item md={6}>
                        <ModalBodyLogout data-test-id="logoutUser" onClick={() => logoutUser()}  >Logout</ModalBodyLogout>
                    </Grid>
                </Grid>
            </ModalBody>
        </Modal>
    )
}

export default LogoutModal;

const ModalBody = styled(Box)({
    position: "relative",
    top: "50%",
    left: "50%",
    cursor: "poniter",
    fontFamily: "Open Sans",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: "40px",
    padding: "30px 30px",
    outline: "none",
    boxSizing: "border-box",
    "& .exitLogoutStyle": {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '50px'
    },
    "& .logoutText": {
        marginTop: "30px"
    },
    "& .allButton": {
        marginTop: "30px"
    },
    "& .modalTitle": {
        color: "#390689",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "center",
        borderBottom: "1px solid #CBD5E1",
        paddingBottom: "30px"
    }, "@media (max-width: 600px)": {
        maxWidth: "385px"
    },
    "@media (max-width: 400px)": {
        maxWidth: "385px",
        width: "85%"
    },
});
const StyledImgBox = styled(Box)({
    display: 'flex',
    height: '100px',
    width: '100px',
    justifyContent: "center",
});
const StyledHeadingBox = styled(Box)({
    color: "#390689",
    fontSize: "14",
    fontWeight: 700,
    display: 'flex',
    justifyContent: "center",
    lineHeight: "22px",
    marginBottom: "4px"
});
const ModalBodyCancel = styled("button")({
    backgroundColor: "#F1F5F9",
    height: "44px",
    color: "#64748B",
    alignSelf: "flex-end",
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "capitalize",
    borderRadius: "30px",
    width: "200px",
    outline: 'none',
    border: 'none',
    cursor: "pointer",
    boxSizing: "border-box",
    "&:hover, &:focus": {
        color: "#64748B !important"
    }
});
const ModalBodyLogout = styled("button")({
    backgroundColor: "#F46C3D",
    height: "44px",
    color: "#fff",
    fontSize: "18px",
    fontWeight: 700,
    outline: 'none',
    border: 'none',
    textTransform: "capitalize",
    borderRadius: "30px",
    width: "200px",
    cursor: "pointer",
    boxSizing: "border-box"
});
